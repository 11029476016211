import React from "react";
import { createSvgIcon } from "@mui/material";

export const FoeFoundryIcon = createSvgIcon(
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="200.000000pt"
    height="200.000000pt"
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M786 1942 l-199 -56 -107 -106 -108 -105 -6 -65 c-5 -54 -10 -68 -31
        -83 -22 -17 -25 -26 -25 -83 0 -36 5 -74 11 -85 6 -12 8 -27 5 -35 -10 -26
        -61 10 -80 56 -20 49 -21 141 -2 178 7 15 16 56 18 92 7 81 40 157 103 234 52
        64 47 69 -17 17 -123 -100 -236 -280 -278 -445 -27 -105 -27 -234 0 -313 67
        -200 65 -196 101 -208 31 -11 32 -12 14 -23 -18 -10 -16 -11 16 -12 26 0 43
        -8 63 -29 l27 -28 -27 -32 c-22 -27 -25 -37 -18 -69 l7 -37 7 39 c7 40 24 58
        50 53 12 -2 14 -20 12 -107 -6 -181 37 -305 148 -423 52 -56 173 -137 186
        -125 2 2 -16 18 -39 35 -51 35 -122 137 -138 196 -13 48 -8 142 9 159 14 14
        42 -7 42 -33 0 -10 9 -21 20 -24 18 -6 19 -12 14 -59 -4 -35 -3 -56 5 -60 7
        -5 11 2 11 18 0 14 5 28 11 31 16 10 59 -4 59 -19 0 -15 312 -362 332 -369 7
        -2 20 -1 28 3 22 12 324 348 328 365 4 17 45 30 61 20 6 -3 11 -21 12 -38 0
        -32 0 -32 10 -7 6 14 8 44 4 67 -5 36 -3 42 15 48 11 3 20 14 20 24 0 10 7 24
        15 31 25 21 39 -3 43 -75 4 -58 1 -73 -27 -129 -21 -43 -52 -84 -97 -125 -60
        -57 -63 -61 -30 -47 142 59 267 222 296 386 7 36 10 108 8 160 -2 78 0 95 12
        97 26 5 43 -13 50 -53 l7 -39 7 37 c7 32 4 42 -18 69 -21 24 -24 34 -16 49 14
        26 42 40 81 40 30 0 31 1 14 14 -18 13 -17 14 12 20 28 6 34 15 51 64 11 31
        31 89 46 127 61 166 11 416 -122 612 -47 69 -145 169 -194 198 -32 18 -31 17
        15 -35 66 -76 108 -167 115 -249 2 -36 11 -78 18 -93 19 -37 18 -129 -2 -178
        -10 -25 -28 -47 -46 -56 -39 -20 -52 -10 -33 26 9 17 15 56 15 94 0 57 -3 66
        -25 83 -21 15 -28 31 -36 88 l-9 70 -98 98 c-89 89 -103 99 -162 116 -36 11
        -78 22 -95 25 -16 3 -1 -3 35 -14 119 -38 108 -21 109 -164 0 -69 -3 -126 -7
        -126 -8 1 -88 74 -242 223 -58 57 -75 71 -38 32 36 -38 69 -81 73 -94 5 -15
        10 -19 14 -12 11 17 64 -46 56 -67 -5 -13 -10 -9 -25 16 -27 43 -32 41 -9 -4
        10 -20 29 -42 43 -49 29 -15 43 -8 19 10 -8 6 -14 17 -12 24 3 7 18 0 39 -20
        19 -17 27 -28 17 -25 -11 3 -15 2 -12 -4 3 -6 14 -10 24 -10 39 0 59 -41 69
        -137 6 -63 15 -101 28 -118 92 -127 99 -148 64 -192 -11 -14 -20 -39 -20 -55
        0 -35 25 -98 39 -98 17 0 33 -40 27 -66 -6 -24 -53 -74 -70 -74 -6 0 -5 5 2
        12 22 22 13 29 -14 11 -24 -16 -28 -16 -59 1 -26 14 -46 17 -101 12 -38 -4
        -90 -8 -116 -9 -27 -1 -43 -6 -38 -11 4 -4 30 -5 57 -2 150 19 147 19 187 -13
        20 -16 42 -31 48 -33 17 -6 0 -28 -21 -28 -10 0 -24 7 -32 16 -41 49 -161 59
        -217 17 -32 -24 -61 -108 -53 -156 5 -32 5 -32 12 10 14 75 28 86 29 21 0 -32
        5 -58 10 -58 6 0 10 12 10 26 0 37 20 19 21 -19 0 -27 2 -29 9 -12 12 29 12 1
        -1 -32 -7 -19 -12 -24 -18 -14 -7 10 -9 9 -14 -3 -4 -9 -13 -13 -22 -10 -8 3
        -15 2 -15 -4 0 -5 6 -12 14 -15 18 -7 26 -85 9 -98 -23 -19 -36 1 -29 46 5 38
        2 45 -18 59 -21 13 -25 13 -30 1 -3 -9 0 -12 9 -9 19 8 32 -79 16 -113 -21
        -47 -36 -21 -38 66 -1 81 -13 131 -13 57 0 -20 -4 -35 -9 -31 -6 3 -8 -16 -5
        -47 6 -59 -5 -84 -31 -74 -14 5 -16 16 -13 54 3 26 8 56 12 67 7 17 6 17 -4 5
        -9 -12 -10 5 -7 74 3 49 1 83 -3 76 -6 -10 -9 -9 -13 1 -3 8 -3 -26 0 -76 4
        -70 2 -87 -7 -75 -10 12 -11 12 -5 -5 4 -11 10 -41 12 -67 5 -39 2 -49 -12
        -54 -26 -11 -37 15 -31 80 3 38 1 54 -5 45 -6 -10 -9 -2 -10 26 l-2 40 -7 -45
        c-4 -25 -5 -63 -2 -86 5 -48 -13 -68 -35 -38 -16 21 -11 116 7 122 5 2 10 8
        10 14 0 7 -5 7 -13 -1 -8 -6 -17 -8 -20 -4 -8 7 -10 -4 -11 -67 -1 -33 -5 -45
        -16 -45 -9 0 -16 11 -18 29 -5 38 6 78 18 71 6 -3 10 1 10 9 0 18 -20 32 -20
        14 -1 -10 -4 -9 -13 2 -7 9 -19 14 -29 12 -21 -4 -31 28 -19 59 10 24 10 23
        11 -6 l2 -30 8 33 c9 40 20 53 20 24 0 -11 5 -24 10 -27 6 -4 10 14 10 46 0
        66 19 54 28 -18 6 -47 6 -48 12 -13 6 38 -11 110 -35 147 -8 12 -26 25 -40 29
        -14 3 -25 12 -25 20 0 8 -5 14 -11 14 -5 0 -7 -4 -4 -10 4 -6 -12 -10 -40 -10
        -50 0 -77 -10 -112 -43 -20 -18 -26 -19 -50 -8 l-28 13 24 9 c13 5 37 21 54
        35 26 22 37 25 83 21 30 -2 51 0 48 4 -9 16 -124 10 -140 -6 -21 -21 -47 -19
        -79 7 l-28 21 13 -22 c6 -13 9 -25 7 -27 -8 -9 -57 46 -63 70 -6 26 10 66 27
        66 14 0 39 63 39 98 0 16 -9 41 -20 55 -34 43 -21 86 54 181 23 29 30 50 33
        94 6 82 9 88 34 66 16 -15 22 -16 28 -5 5 8 15 10 22 7 8 -4 5 0 -5 8 -24 19
        -40 21 -30 4 4 -7 -2 -5 -14 6 -18 15 -19 22 -10 40 6 11 83 92 172 179 l161
        159 -165 -156 c-132 -124 -166 -152 -172 -138 -4 9 -7 67 -7 128 l-1 111 188
        52 c103 28 191 51 196 51 5 0 1 -10 -10 -22 l-19 -23 26 22 25 23 24 -23 24
        -22 -18 23 c-24 28 -21 28 93 -4 51 -14 95 -23 97 -21 3 3 -132 42 -216 62 -8
        2 -104 -22 -214 -53z m995 -214 c30 -45 48 -82 43 -89 -4 -7 -3 -9 3 -6 10 7
        59 -90 50 -99 -2 -3 -12 16 -22 41 -25 67 -28 50 -4 -25 25 -81 35 -219 21
        -286 -12 -56 -52 -168 -58 -162 -2 2 3 28 11 58 8 30 14 66 14 80 0 20 -3 17
        -15 -15 l-14 -40 6 35 c30 209 28 269 -13 385 -36 101 -64 151 -122 224 -28
        35 -51 66 -51 69 0 18 108 -104 151 -170z m-1431 158 c0 -2 -21 -30 -46 -62
        -76 -98 -127 -216 -144 -332 -8 -62 -2 -199 14 -277 6 -28 5 -28 -8 5 -22 53
        -20 8 4 -75 15 -53 16 -65 5 -51 -8 11 -15 26 -15 33 0 7 -5 13 -10 13 -6 0
        -9 3 -8 8 1 4 -6 40 -16 81 -29 115 -18 233 38 396 4 11 -2 4 -13 -15 -27 -47
        -27 -30 0 23 49 96 199 287 199 253z m230 -287 c-3 -3 -47 12 -98 34 l-94 40
        94 94 93 94 5 -128 c3 -71 3 -131 0 -134z m933 163 l88 -88 -93 -41 c-51 -22
        -95 -39 -98 -38 -8 5 0 255 8 255 4 0 47 -40 95 -88z m268 -135 c16 -44 28
        -81 25 -84 -2 -2 -7 5 -11 16 -7 23 -45 49 -45 31 0 -5 4 -10 8 -10 15 0 42
        -58 43 -92 l1 -33 7 30 c6 25 9 19 16 -38 5 -51 4 -71 -5 -80 -10 -9 -11 -9
        -6 1 4 6 3 12 -3 12 -5 0 -11 6 -14 13 -2 6 -2 1 0 -12 4 -24 -23 -119 -38
        -135 -13 -14 -11 1 3 27 6 12 13 73 15 135 4 98 2 118 -15 145 -11 19 -19 53
        -20 82 0 28 -6 64 -11 80 -25 68 19 -10 50 -88z m-1528 36 c-3 -42 -12 -83
        -25 -107 -18 -34 -20 -50 -15 -125 4 -49 2 -92 -3 -101 -5 -8 -10 -13 -11 -10
        0 3 -3 49 -5 103 -5 95 -5 100 22 140 l27 41 -26 -17 c-15 -10 -27 -15 -27
        -12 0 9 61 155 65 155 1 0 1 -30 -2 -67z m165 -38 c-3 -3 -9 2 -12 12 -6 14
        -5 15 5 6 7 -7 10 -15 7 -18z m-297 -82 c-12 -20 -14 -14 -5 12 4 9 9 14 11
        11 3 -2 0 -13 -6 -23z m253 -20 c-4 -21 -11 -41 -17 -44 -6 -4 -7 -25 -3 -50
        6 -41 5 -43 -10 -24 -17 23 -13 66 13 121 21 44 26 43 17 -3z m1285 -78 c0
        -11 -6 -33 -14 -50 -13 -29 -14 -27 -8 28 6 57 -1 74 -15 37 -4 -12 -7 4 -7
        41 l0 61 22 -48 c13 -27 23 -58 22 -69z m-1282 3 c-3 -8 -6 -5 -6 6 -1 11 2
        17 5 13 3 -3 4 -12 1 -19z m40 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
        -12 1 -19z m-56 -201 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
        m1273 8 c3 -8 1 -15 -3 -15 -5 0 -11 7 -15 15 -3 8 -1 15 3 15 5 0 11 -7 15
        -15z m-454 -379 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z
        m87 -171 c-1 -25 -3 -45 -4 -45 -11 0 -23 47 -18 71 10 43 24 26 22 -26z
        m-524 24 c5 -19 -7 -69 -16 -69 -2 0 -5 20 -5 45 -2 46 11 61 21 24z m857 -14
        c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m-45
        -85 c19 -37 19 -164 0 -205 l-14 -30 7 30 c10 44 6 176 -7 208 -14 36 -5 35
        14 -3z m-1116 -40 c-6 -29 -9 -80 -5 -114 3 -40 2 -54 -4 -41 -12 29 -12 146
        1 184 17 47 21 31 8 -29z m298 3 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2
        -26 0 -35z m44 17 c5 0 9 8 9 17 0 12 3 14 11 6 13 -13 8 -83 -6 -84 -5 0 -13
        10 -17 23 -3 13 -6 17 -7 11 -2 -26 -21 -12 -21 15 0 32 13 52 18 28 2 -9 8
        -16 13 -16z m457 -21 c-4 -33 -4 -34 -15 -9 -12 28 -5 66 9 52 5 -5 8 -25 6
        -43z m19 4 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-51 3 c6
        -37 -1 -60 -17 -51 -12 8 -12 85 1 85 5 0 12 -15 16 -34z m-446 -60 c0 -8 -4
        -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m484 -14
        c-6 -4 -16 -16 -22 -27 -11 -19 -11 -19 -12 3 0 12 5 22 10 22 6 0 10 8 10 17
        0 14 3 14 12 5 9 -9 10 -15 2 -20z m-465 -29 c0 -25 -1 -26 -9 -8 -12 27 -12
        35 0 35 6 0 10 -12 9 -27z m31 -30 c0 -35 -3 -44 -10 -33 -13 20 -13 80 0 80
        6 0 10 -21 10 -47z m390 15 c0 -53 -17 -67 -22 -19 -2 23 -2 44 1 47 12 12 21
        1 21 -28z m-341 8 c14 -16 2 -78 -14 -77 -6 1 -14 15 -18 32 -13 50 6 77 32
        45z m301 -29 c0 -50 -7 -62 -27 -46 -17 14 -17 76 0 82 24 10 27 6 27 -36z
        m-230 -14 c0 -52 -14 -73 -29 -45 -12 23 -15 65 -4 75 18 19 33 5 33 -30z
        m165 14 c7 -27 -11 -81 -24 -73 -12 8 -21 -76 -10 -88 5 -6 9 3 9 20 0 17 7
        39 16 50 15 19 16 19 9 -1 -8 -23 -8 -24 -6 -48 0 -10 5 -16 10 -12 5 3 13 0
        17 -6 10 -16 -16 -29 -48 -24 -15 3 -30 1 -33 -5 -7 -12 -55 -13 -73 -2 -15
        10 -32 67 -31 102 1 16 3 19 6 8 2 -9 13 -20 25 -23 14 -5 18 -13 14 -28 -5
        -21 -5 -21 9 -3 17 23 20 40 5 31 -12 -8 -13 5 -1 24 7 11 10 9 16 -8 7 -22
        25 -29 25 -10 0 5 4 8 9 5 11 -7 21 28 21 77 0 42 26 53 35 14z m-110 -24
        c-10 -55 -22 -61 -34 -17 -15 53 -15 54 15 54 25 0 26 -2 19 -37z m64 25 c0
        -7 -6 -29 -14 -48 l-13 -35 -11 28 c-18 47 -14 67 14 67 14 0 25 -6 24 -12z
        m-214 -154 c1 -18 9 -41 16 -50 13 -16 12 -16 -7 -4 -31 19 -41 40 -48 100
        l-7 55 22 -33 c12 -19 23 -49 24 -68z m360 54 c-4 -24 -8 -47 -9 -53 -2 -17
        -40 -47 -44 -36 -7 20 41 129 56 131 2 0 1 -19 -3 -42z m-310 -23 l15 -40 -4
        43 c-5 51 0 53 20 7 17 -41 18 -75 3 -75 -6 0 -18 -8 -27 -18 -10 -10 -13 -13
        -9 -6 4 7 3 15 -3 19 -6 4 -10 17 -8 30 2 13 -7 37 -19 54 -22 29 -22 30 -3
        28 13 -1 25 -15 35 -42z m265 36 c-1 -8 -37 -51 -44 -51 -3 0 -1 13 5 28 5 16
        16 29 24 29 8 0 15 -3 15 -6z m-30 -135 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
        -4 4 13 16 21 21 21 13z m-214 -19 c12 4 15 3 10 -5 -10 -17 -41 -15 -57 5
        -13 15 -12 15 8 5 12 -7 29 -9 39 -5z"
      />
      <path
        d="M760 1640 c0 -12 5 -14 66 -29 90 -23 127 -103 62 -132 -24 -10 -34
        -22 -36 -41 -3 -25 0 -28 25 -28 15 0 37 7 47 15 25 19 87 19 112 0 10 -8 32
        -15 47 -15 24 0 28 4 25 22 -2 13 -19 33 -40 47 -21 13 -38 30 -38 37 0 20 41
        44 77 44 41 0 82 16 111 43 l24 22 -84 8 c-149 15 -398 19 -398 7z"
      />
      <path
        d="M690 1446 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
        13z"
      />
      <path
        d="M536 1298 c-14 -13 -26 -30 -27 -38 -3 -19 9 -67 27 -107 8 -17 14
        -40 14 -51 0 -29 58 -105 76 -98 9 3 12 1 9 -4 -7 -12 36 -30 70 -30 13 0 54
        16 91 35 37 19 69 34 71 32 2 -2 -7 -16 -19 -32 l-21 -28 22 13 c28 14 38 6
        21 -17 -7 -10 1 -3 19 14 l31 33 6 -25 c5 -20 0 -31 -20 -50 -25 -23 -38 -74
        -15 -60 7 5 8 -4 4 -27 -6 -36 9 -74 37 -92 13 -8 22 -5 41 12 l24 22 18 -20
        c24 -27 39 -25 65 7 17 22 21 36 16 68 -5 28 -3 36 4 25 8 -12 10 -9 10 13 0
        17 -10 37 -25 51 -14 13 -25 30 -25 37 0 8 -13 25 -30 39 -17 14 -30 32 -31
        40 0 11 -3 9 -10 -5 -6 -11 -22 -29 -36 -39 l-26 -19 6 34 c6 32 6 33 -8 15
        -20 -26 -19 -11 3 41 10 26 22 41 29 38 7 -2 12 -1 11 3 -1 4 4 16 11 28 14
        21 14 21 43 -11 25 -29 58 -92 58 -112 0 -4 -7 2 -15 13 -8 10 -15 14 -15 8 0
        -6 14 -26 31 -45 18 -19 28 -28 24 -20 -6 11 -2 13 19 7 27 -7 27 -6 12 16
        -24 34 -19 42 13 19 42 -30 112 -61 139 -61 63 0 152 81 152 140 0 14 7 35 15
        46 8 10 15 34 15 52 0 20 4 31 10 27 18 -11 11 21 -10 48 -27 34 -70 43 -109
        23 -36 -19 -45 -20 -34 -3 12 20 -49 -23 -85 -60 -41 -41 -52 -42 -52 0 0 24
        -3 28 -10 17 -8 -13 -11 -12 -24 5 -9 11 -16 23 -16 28 0 4 -5 7 -11 7 -8 0
        -8 -4 -2 -12 6 -7 13 -25 17 -41 6 -26 5 -27 -21 -21 -16 4 -36 16 -46 28 -17
        21 -17 21 -6 -6 7 -16 19 -28 28 -28 9 0 23 -5 31 -10 23 -15 -5 -30 -57 -30
        -47 0 -63 17 -63 67 0 15 -5 35 -10 43 -9 13 -11 13 -20 0 -5 -8 -7 -27 -4
        -42 3 -15 0 -36 -6 -48 -10 -18 -19 -21 -62 -18 -55 3 -75 22 -34 32 14 3 31
        16 37 28 l12 21 -28 -21 c-15 -12 -34 -22 -41 -22 -19 0 -18 25 2 44 8 9 11
        16 6 16 -5 0 -17 -10 -26 -22 -12 -16 -18 -19 -22 -10 -10 23 -24 12 -18 -14
        6 -25 6 -26 -17 -13 -13 7 -37 25 -52 41 -32 31 -74 58 -63 39 9 -14 5 -14
        -37 4 -49 20 -61 19 -91 -7z"
      />
      <path d="M1020 1121 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
      <path
        d="M783 968 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12 -13 0
        -20 -5 -17 -12z"
      />
      <path d="M825 920 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z" />
    </g>
  </svg>,
  "Foe Foundry"
);
